<template>
  <div>
    <!-- 公共头部 -->
   <Header></Header>
    <!--  内页轮播图 -->
    <!-- <Otherban :banner="banner" v-show="bannerStatus==1"></Otherban> -->
    <div class="netcall_wrap">
      <div class="item" v-for="(item, index) in list" :key="index">
        <wd-cell-group :title="item.title" center>
          <wd-cell>
            <div class="wrap">

              <div class="right">
                <div
                  id="right_wrap"
                  @click="calltel(item)"
                  v-for="(item, index) in item.tels"
                  :key="index"
                >
                  <div class="num">{{ item }}</div>
                  <div class="button">立即咨询</div>
                </div>
              </div>
            </div>
          </wd-cell>
        </wd-cell-group>
      </div>
    </div>

    <!-- 公共底部 -->

    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Header from "@/components/Public/Header.vue";
import Footer from "@/components/Public/Footer.vue";
import Otherban from "@/components/otherbanner/index.vue";
export default {
  data() {
    return {
      list: [],
      telList: [],
      banner: "",
      bannerStatus: "",
    };
  },
  created() {
    this.getList();
    this.getbanner();
  },
  methods: {
    async getList() {
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.get("/school/school");
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "学校简介 联系方式：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        this.list = res.data;
      } else {
        this.list = [];
      }
    },
    calltel(e) {
      console.log(e);
      window.location.href = "tel:" + e;
    },
    // 获取banner图
    async getbanner() {
      this.$toast.loading("加载中");
      const { data: res } = await this.$http.post("/other/advert", { id: 53 });
      console.log(res);
      if (res.code == 1) {
        this.bannerStatus = res.data.status;
        this.banner = "https://tanpei.ks265.net/" + res.data.img;
      }
    },
  },
  components: {
    Header,
    Footer,
    Otherban,
  },
};
</script>
 <style scoped>
/deep/.netcall_wrap .wd-cell-group .wd-cell-group__body .wd-cell__left {
  display: none;
}
/deep/.netcall_wrap .wd-cell-group .wd-cell-group__body .wd-cell {
  padding-left: 0;
}
/deep/.netcall_wrap .wd-cell-group .wd-cell-group__body {
  border-top: 1px solid #f3f3f3;
}
/deep/.netcall_wrap .wd-cell-group .wd-cell__wrapper {
  padding: 0.2rem;
}
/deep/.netcall_wrap .wd-cell-group {
  border-radius: 0.2rem;
  padding: 0 0.3rem;
}

/deep/.netcall_wrap .wd-cell-group .wd-cell-group__title .wd-cell-group__left {
  width: 100%;
  font-weight: 600;
}

.netcall_wrap{margin: 0.5rem 0;}
.wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

}
.netcall_wrap .item {
  margin: 0.2rem;
  border-bottom: 1px solid #f3f3f3;
}
.wrap .left {
  display: flex;
  justify-content: center;
  align-items: center;

}
.wrap .right {
  /* margin-right: 0.5rem; */
  font-size: 0.28rem;
  color: #333;
  padding: 0 0.1rem;
}
.wrap .right #right_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.2rem 0;
}
.wrap .icon {
  margin-right: 0.3rem;
}
.wrap .number {
  color: #ff6000;
  font-size: 0.3rem;
  font-weight: 600;
}
.button {
  border: 1px solid #86cb08;
  color: #86cb08;
  margin-left: 0.5rem;
  padding: 0rem 0.2rem;
  border-radius: 0.5rem;
}
</style>